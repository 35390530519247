
<template>
  <div class="c-app flex-row align-items-center">
    <CContainer>
      <CRow class="justify-content-center">
        <CCol md="8">
          <CCardGroup>
            <CCard class="p-4">
              <CCardBody>
                <CForm @submit.stop.prevent="onSubmitEmail">
                  <h1>Forgot Password</h1>
                  <span v-if="isLoggedIn">{{ redirect() }}</span>
                  <p class="text-muted">Forgot Password</p>
                  <div></div>
                  <input
                    type="email"
                    placeholder="Registered Email Address"
                    id="email12"
                    v-model="details.email"
                    name="email"
                    class="form-control mb-2"
                    required
                  />

                  <CRow>
                    <CCol col="6" class="text-left">
                      <b-button type="submit" variant="primary"
                        >Submit</b-button
                      >
                    </CCol>
                    <span>{{ message }}</span>
                  </CRow>
                </CForm>
              </CCardBody>
            </CCard>
            <CCard
              color="primary"
              text-color="white"
              class="text-center py-5 d-md-down-none"
              body-wrapper
            >
              <CCardBody>
                <h2>ExpoDataBox</h2>
                <p>
                  Expand your business with the great features provided to our
                  clients.
                </p>
              </CCardBody>
            </CCard>
          </CCardGroup>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import HeaderStats from "../../components/Headers/HeaderStats";
export default {
  name: "login",
  components: {
    HeaderStats,
  },
  data() {
    return {
      message: null,
      details: {
        email: null,
      },
    };
  },

  computed: {
    ...mapGetters(["errors", "user"]),
    isLoggedIn: function () {
      return localStorage.getItem("token");
    },
  },

  methods: {
    ...mapActions(["SendRequestdone", "ForgotPassword"]),

    redirect() {
      this.$router.push("/");
    },
    onSubmitEmail() {
      this.ForgotPassword(this.details).then((res) => {
        if (res.data == 1) {
          //this.message = "Email Send For Reset Password ";
           let message = "";
          let title = "Reset link Sent to Your Email";
          let type = "success";
          this.$alert(message, title, type).then(() => {
            window.location.href = "/";
          });
          this.UpdateForgotPassword(this.details).then((response) => {
         
          });
        } else {
           let message = "";
          let title = "Email Does not Exists";
          let type = "error";
          this.$alert(message, title, type).then(() => {
          });
        }
      });
    },
  },
};
</script>
<style scoped>
.red {
  color: red;
}
</style>